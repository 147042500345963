import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountFarmsOptions } from '../actions/accountFarmAction';
import useFetchApi from './useFetchApi';
import { GetFarmsByAccountIdResponse } from '../requests/api/apiTypes';
import { ReduxState } from '../types';

const useFetchFarms = () => {
  const dispatch = useDispatch();
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);

  const fetchResponse = useFetchApi<GetFarmsByAccountIdResponse>({
    modelEndpoint: 'accountsDetail',
    modelName: 'farms',
    id: selectedAccount?.id ?? '',
    dependencies: [selectedAccount?.id ?? ''],
  });

  useEffect(() => {
    if (fetchResponse?.data) {
      dispatch(setAccountFarmsOptions(fetchResponse.data.allFarms ?? []));
    }
  }, [fetchResponse, dispatch]);

  return fetchResponse;
};

export default useFetchFarms;
