import { getFilteredReadings } from '../dataServices';
import { getGranularity, buildCategories } from '../../../utils/highcharts';
// To Do: Actualizar types
const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: number;
}) => {
  // Ejecutar llamada API para datos de Soil Moisture
  let hourInterval = 4;
  const granularity = getGranularity(filters.startDate, filters.endDate);
  if (granularity === 'hourly') {
    hourInterval = 1;
  }

  const [soilMoistureData] = await Promise.all([
    getFilteredReadings({
      ...filters,
      endpoint: '/daily',
      sensorType: 'Soil Moisture',
      hourInterval,
    }),
  ]);

  // Agrupar datos por profundidad (sensorDepth)
  const groupedByDepth = soilMoistureData.reduce((acc: Record<string, any[]>, item: any) => {
    if (!acc[item.sensorDepth]) acc[item.sensorDepth] = [];
    acc[item.sensorDepth].push(item);
    return acc;
  }, {});

  // Crear series para el gráfico
  const series = Object.entries(groupedByDepth).map(([depth, data]) => {
    const typedData = data as any[]; // Type assertion to tell TypeScript this is an array
    // Agrupar los datos por fecha
    const groupedByDate = typedData.reduce((acc: Record<string, any[]>, item: any) => {
      if (!acc[item.date]) acc[item.date] = [];
      acc[item.date].push(item);
      return acc;
    }, {});

    // Crear puntos para cada día
    const points = Object.entries(groupedByDate).map(([date, items]) => {
      // Ordenar por intervalo horario
      const sortedItems = items.sort((a, b) => a.hourInterval - b.hourInterval);

      return {
        x: date, // Categoría principal es la fecha
        y: sortedItems.map((item) => item.averageValue), // Valores promedio por hora del día
      };
    });

    return {
      name: `${depth} cm`,
      data: points.flatMap((point) => point.y), // Aplanar para obtener todos los valores promedio
    };
  });

  const categories = buildCategories(granularity, filters.startDate, filters.endDate, hourInterval);
  console.log('categories', categories);

  return {
    series,
    categories: Array.isArray(categories) ? categories : categories.categories,
    categoriesByIndex: Array.isArray(categories) ? categories : categories.categoriesByIndex,
    intervalStartHours: Array.isArray(categories) ? categories : categories.intervalStartHours,
    intervalEndHours: Array.isArray(categories) ? categories : categories.intervalEndHours,
  };
};

export default prepareData;
