import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BaseHighchart from '../BaseHighchart';
import prepareData from '../StackedSoilMoistureChart/builder';

import {
  ChartDataType,
  ChartFilters,
} from '../types';

import type PreparedData from './types';
import { ReduxState } from '../../../types';

function SoilMoistureChart({
  sectorId,
  farmId,
  hideFilter = true,
}: {
  sectorId: string
  farmId: string
  hideFilter?: boolean
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
  });

  const [chartConfig, setChartConfig] = useState<Highcharts.Options>({});

  const title = 'Gráfico de Humedad del Suelo Acumulada';
  const yAxisTitle = 'Humedad del Suelo Acumulada (%)';

  const plotBands = [
    {
      from: 0,
      to: 30,
      color: 'rgba(255, 0, 0, 0.1)',
      name: '',
    },
    {
      from: 30,
      to: 58,
      color: 'rgba(255, 255, 0, 0.3)',
      name: 'Onset of Stress',
    },
    {
      from: 58,
      to: 74,
      color: 'rgba(144, 238, 144, 0.4)',
      name: 'Bajo Metabolismo',
    },
    {
      from: 74,
      to: 90,
      color: 'rgba(144, 238, 144, 0.5)',
      name: 'Alto Metabolismo',
    },
    {
      from: 90,
      to: 96,
      color: 'rgba(144, 238, 144, 0.6)',
      name: 'Capacidad de Campo Practica',
    },
    {
      from: 96,
      to: 1000,
      color: 'rgba(173, 216, 230, 0.3)',
      name: 'Full Point',
    },
  ];

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  // Creo que no es necesario const wiseconnZoneId = '';

  const [selectedDepths, setSelectedDepths] = useState<string[]>([]); // Profundidades seleccionadas
  const [availableDepths, setAvailableDepths] = useState<string[]>([]); // Profundidades disponibles

  const calculateSummedSeries = (data: PreparedData, depthsToSum: string[]) => {
    const depthSeries = data.series.filter(
      (item: { name: string; data: number[] }) => depthsToSum.includes(item.name),
    );
    const summedData = depthSeries.reduce(
      (acc: number[], curr: { name: string; data: number[] }) => {
        curr.data.forEach((value: number, index: number) => {
          acc[index] = (acc[index] || 0) + value;
        });
        return acc;
      },
      [],
    );
    return summedData.map((y: number, x: number) => ({
      x,
      y,
    }));
  };

  const fetchData = async (filters: { startDate: string; endDate: string }) => {
    const chartFilters: ChartFilters = {
      farmId,
      startDate: filters.startDate,
      endDate: filters.endDate,
    };
    if (sectorId) {
      chartFilters.sectorId = sectorId;
    }

    // if (wiseconnZoneId) {
    //   chartFilters.wiseconnZoneId = wiseconnZoneId;
    // } Creo que no es necesario
    const preparedData: PreparedData = await prepareData(chartFilters);

    // Extraer profundidades disponibles
    const depths = preparedData.series.map((item: any) => item.name);
    setAvailableDepths(depths);

    if (depths.length > 0 && selectedDepths.length === 0) {
      setSelectedDepths([depths[0]]);
    }

    // Calcular datos sumados según las profundidades seleccionadas
    const summedSeries = calculateSummedSeries(preparedData, selectedDepths);

    // Transform data into x,y point format
    setChartData({
      series: selectedDepths.length > 0
        ? [{ type: 'line', name: 'Suma de Profundidades', data: summedSeries }]
        : [], // Empty series when no depths selected
      categories: preparedData.categories,
    });

    setChartConfig({
      yAxis: {
        min: 0,
        // max: Math.max(
        //   ...summedSeries.map((point) => point.y),
        // ),
        max: 150,
      },
    });
  };

  const handleDepthChange = (depth: string) => {
    const updatedDepths = selectedDepths.includes(depth)
      ? selectedDepths.filter((d) => d !== depth)
      : [...selectedDepths, depth];
    setSelectedDepths(updatedDepths);
    fetchData({ startDate, endDate });
  };

  useEffect(() => {
    fetchData({ startDate, endDate });
  }, [startDate, endDate, selectedDepths]);

  // Llamada inicial al cargar el componente
  useEffect(() => {
    fetchData({ startDate, endDate });
  }, [startDate, endDate]);

  const customTooltip = {
    shared: true,
    useHTML: true,
    formatter(
      this: Highcharts.TooltipFormatterContextObject,
    ) {
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${points}</div>`;
    },
  };

  return (
    <div>
      <BaseHighchart
        title={title}
        categories={chartData.categories}
        yAxisTitle={yAxisTitle}
        series={chartData.series as Highcharts.SeriesOptionsType[]}
        tooltip={customTooltip}
        chartConfig={chartConfig}
        plotBands={plotBands}
        hideFilter={hideFilter}
        sectorId={sectorId}
        // tooltipText="Tooltip de ejemplo"
      />
      <div>
        <h3>Seleccione Niveles:</h3>
        {availableDepths.map((depth) => (
          <label key={depth}>
            <input
              type="checkbox"
              checked={selectedDepths.includes(depth)}
              onChange={() => handleDepthChange(depth)}
            />
            {depth}
          </label>
        ))}
      </div>
    </div>
  );
}

export default SoilMoistureChart;
