import React from 'react';
import RainfallChart from '../../../../../../components/Highcharts/RainfallChart/RainfallChart';

const WeatherGraphsTab = ({ sectorId, farmId }: { sectorId: string, farmId: string }) => (
    <>
      <RainfallChart
        sectorId={sectorId}
        farmId={farmId}
      />
    </>
);

export default WeatherGraphsTab;
