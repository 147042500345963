import React from 'react';
import { Box, Typography } from '@mui/material';
import { WeatherForecastData } from '../utils';
import useStyles from '../styles';

const DailyForecast = ({
  day,
  date,
  minTemperature,
  maxTemperature,
  RainMm,
  RainProbability,
  eto,
  weather,
}: WeatherForecastData) => {
  const classes = useStyles();
  const isRainy = weather === 'Lluvioso';

  return (
    <Box className={classes.dailyForecastBox}>
      <Typography variant="body2">{`${day ?? ''} ${date ?? ''}`}</Typography>

      <Typography
        className={`${classes.weatherText} ${isRainy ? classes.rainyWeatherText : ''}`}
      >
        {weather}
      </Typography>

      <Typography className={classes.rainText}>
        {minTemperature !== null && maxTemperature !== null
          ? `${Math.round(minTemperature)}°. ${Math.round(maxTemperature)}°.`
          : 'N/A'}
      </Typography>

      <Typography variant="body2" color="textSecondary">
        {`ETO: ${eto ?? 'N/A'}`}
      </Typography>

      {isRainy && (
        <Box className={classes.rainContainer}>
          <Typography className={classes.rainText}>
            {`${RainMm} mm`}
          </Typography>
          <Typography className={classes.rainText}>
            {`${RainProbability}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DailyForecast;
