/* eslint-disable global-require */
import React, {
  CSSProperties, memo, useCallback, useRef,
} from 'react';
import {
  GoogleMap, Marker, Polygon,
} from '@react-google-maps/api';
import { Box, Typography } from '@mui/material';

const containerStyle: CSSProperties = {
  display: 'flex',
  flex: 1,
  height: '100%',
  position: 'relative',
};

const infoBoxStyle: CSSProperties = {
  position: 'absolute',
  top: '10px',
  left: '3%',
  fontSize: '12px',
  transform: 'translateX(-3%)',
  backgroundColor: 'white',
  padding: '4px 8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  zIndex: 10,
  maxWidth: '150px',
};

const sectorInfoBoxStyle: CSSProperties = {
  position: 'absolute',
  top: '10px',
  left: '10px',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: '15px',
  borderRadius: '4px',
  zIndex: 1000,
  maxWidth: '250px',
  boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
};

type EntityToDraw = google.maps.Polygon | google.maps.Marker;

const ENTITY_MAP: Record<'polygon' | 'marker', 'POLYGON' | 'MARKER'> = {
  polygon: 'POLYGON',
  marker: 'MARKER',
};

const polygonColor = (isSelected?: boolean, isLinked?: boolean) => {
  if (isSelected) {
    return '#3693D5';
  } if (isLinked) {
    return '#54B03A';
  }
  return '#FFFFFF';
};

type SectorInfo = {
  data: {
    [key: string]: string;
  };
};

function GoogleMaps({
  lat, lng, drawable, polygons, markers, setCoordinates, handleCenterChange, farm, handleZoomChange,
  sectorInfo,
}: {
  // lat and lng needed when there is still no farm and I am positioning myself when creating it
  // using GoogleAutocomplete
  lat: string | number,
  lng: string | number,
  drawable: 'polygon' | 'marker' | 'none',
  farm?: {
    id: string,
    coordinates: { lat: number, lng: number }[] | null,
    zoom?: number,
  },
  polygons?: {
    id?: string,
    coordinates?: { lat: number, lng: number }[],
    isSelected?: boolean,
    isLinked?: boolean,
    onPolygonClick?: VoidFunction,
  }[]
  markers?: {
    id?: string,
    coordinates?: { lat: number, lng: number },
    isSelected?: boolean,
    deviceType: string,
    isGatewayNode?: boolean,
    onMarkerClick?: VoidFunction,
  }[],
  setCoordinates?: React.Dispatch<React.SetStateAction<{ lng: number, lat: number }[] | null>>
  handleCenterChange?: (centerCoordinates: {
    lat: () => string;
    lng: () => string;
  }) => void,
  handleZoomChange?: (zoom: number) => void,
  sectorInfo?: SectorInfo | null,
}) {
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  let currentEntity: EntityToDraw | null = null;

  const onLoad = useCallback((mapElem) => {
    mapRef.current = mapElem;

    if (drawable !== 'none') {
      const entityToDraw = ENTITY_MAP[drawable];
      const eventListenerToAdd = `${drawable}complete`;
      // Initialize the DrawingManager and add it to the map
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType[entityToDraw],
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            window.google.maps.drawing.OverlayType[entityToDraw],
          ],
        },
        polygonOptions: {
          fillColor: '#FF0000',
          fillOpacity: 0.5,
          strokeWeight: 2,
          strokeColor: '#FF0000',
          clickable: true,
          editable: true,
          zIndex: 1,
        },
      });
      drawingManager.setMap(mapElem);

      drawingManagerRef.current = drawingManager;

      // Add event listener for when a polygon is drawn
      window.google.maps.event.addListener(drawingManager, eventListenerToAdd, (
        entity: EntityToDraw,
      ) => {
        if (setCoordinates) {
          if (drawable === 'polygon' && entity instanceof google.maps.Polygon) {
            setCoordinates(entity.getPath().getArray().map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })));
          }

          if (drawable === 'marker' && entity instanceof google.maps.Marker) {
            setCoordinates([{
              lat: entity.getPosition()?.lat() ?? 0,
              lng: entity.getPosition()?.lng() ?? 0,
            }]);
          }
        }
        // --
        if (currentEntity) {
          currentEntity.setMap(null);
        }

        currentEntity = entity;
        drawingManager.setDrawingMode(null);

        // Allow deletion of polygons on right-click
        window.google.maps.event.addListener(currentEntity as EntityToDraw, 'rightclick', () => {
          if (currentEntity) {
            currentEntity.setMap(null);
            currentEntity = null;
            drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType[entityToDraw]);
          }
        });
      });
    }
  }, []);

  const onUnmount = useCallback(() => {
    if (drawingManagerRef.current) {
      // Clear event listeners on unmount
      window.google.maps.event.clearListeners(drawingManagerRef.current, `${drawable}complete`);
      if (currentEntity) window.google.maps.event.clearListeners(drawingManagerRef.current, 'rightclick');

      if (drawingManagerRef.current) drawingManagerRef.current.setMap(null);
      if (mapRef.current) mapRef.current = null;
    }
  }, []);

  return (
    <Box style={containerStyle}>
      {sectorInfo && (
        <Box sx={sectorInfoBoxStyle}>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Resumen sector
          </Typography>
          {Object.entries(sectorInfo.data).map(([key, value]) => (
            <Box key={key} sx={{ marginBottom: '4px' }}>
              <Typography variant="body2" component="span" color="textSecondary">
                {key}:{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      <GoogleMap
        mapContainerStyle={{ flex: 1, height: '100%' }}
        center={{
          lat: typeof lat === 'string' ? parseFloat(lat) : lat,
          lng: typeof lng === 'string' ? parseFloat(lng) : lng,
        }}
        zoom={farm?.zoom ?? 18}
        options={{
          mapTypeControl: false,
          mapTypeId: 'satellite',
          streetViewControl: false,
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onCenterChanged={() => {
          if (handleCenterChange && mapRef.current) {
            const center = mapRef.current.getCenter();
            if (center) {
              handleCenterChange({
                lat: () => center.lat().toString(),
                lng: () => center.lng().toString(),
              });
            }
          }
        }}
        onZoomChanged={() => {
          if (handleZoomChange && mapRef.current) {
            const currentZoom = mapRef.current.getZoom() as number;
            handleZoomChange(currentZoom);
          }
        }}
      >
        {farm && farm.coordinates && <Polygon
          key={farm.id}
          path={farm.coordinates.map((coord) => ({
            lat: coord.lat,
            lng: coord.lng,
          }))}
          options={{
            strokeColor: '#FFFFFF',
            strokeWeight: 4,
          }}
        />}

        {polygons && polygons.map((polygon) => (
          <Polygon
            onClick={polygon.onPolygonClick}
            key={polygon.id}
            path={polygon.coordinates && polygon.coordinates.map((coord) => ({
              lat: coord.lat,
              lng: coord.lng,
            }))}
            options={{
              fillColor: polygonColor(polygon.isSelected, polygon.isLinked),
              // polygon.isSelected ? '#3693D5' : '#FFFFFF',
              fillOpacity: 0.4,
              strokeColor: polygonColor(polygon.isSelected, polygon.isLinked),
              // polygon.isSelected ? '#3693D5' : '#FFFFFF',
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        ))}
        {markers && markers.map((marker) => {
          if (marker.coordinates) {
            let iconToReturn = '';
            if (marker.deviceType === 'node') {
              if (marker.isGatewayNode) {
                iconToReturn = marker.isSelected
                  ? require('../../../assets/images/devicesMarkers/nodo_gw_selected.png')
                  : require('../../../assets/images/devicesMarkers/nodo_gw.png');
              } else {
                iconToReturn = marker.isSelected
                  ? require('../../../assets/images/devicesMarkers/nodo_selected.png')
                  : require('../../../assets/images/devicesMarkers/nodo.png');
              }
            } else if (marker.deviceType === 'sensor') {
              iconToReturn = marker.isSelected
                ? require('../../../assets/images/devicesMarkers/sensor_selected.png')
                : require('../../../assets/images/devicesMarkers/sensor.png');
            }

            return <Marker
              onClick={marker.onMarkerClick}
              key={marker.id}
              position={marker.coordinates}
              icon={iconToReturn}
            />;
          }
          return null;
        })}
      </GoogleMap>
      {drawable !== 'none' && <Box style={infoBoxStyle}>
        {`To delete, right-click on ${drawable}`}
      </Box>}

    </Box>

  );
}

export default memo(GoogleMaps);
