import dayjs from 'dayjs';
import { getFilteredReadings } from '../dataServices';
import { getGranularity, buildCategories } from '../../../utils/highcharts';

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: number;
}) => {
  console.log('filters', filters);
  const granularity = getGranularity(filters.startDate, filters.endDate);
  console.log('granularity', granularity);
  let hourInterval = 24;

  if (granularity === 'hourly') {
    hourInterval = 1;
  }

  // Execute API calls concurrently
  const [rainData] = await Promise.all([
    getFilteredReadings({
      ...filters,
      sensorType: 'Rain',
      hourInterval,
      endpoint: `/${granularity}`,
    }),
  ]);
  console.log('rainData', rainData);

  // Create a mapping for `rainData` based on granularity
  const rainDataMap = new Map(
    rainData.map((item: any) => [
      granularity === 'hourly' ? item.hour : dayjs(item.date).format('MMM DD'),
      item.cumulativeTotal,
    ]),
  );
  console.log('rainDataMap', rainDataMap);

  // Build categories based on granularity
  const categories = buildCategories(granularity, filters.startDate, filters.endDate, hourInterval);
  console.log('categories', categories);

  // Normalize `rainData` to ensure alignment with `categories`
  const normalizedRainData = (Array.isArray(categories) ? categories : categories.categories)
    .map((category: any) => rainDataMap.get(category) || 0);
  console.log('normalizedRainData', normalizedRainData);

  // Calculate cumulative rain by adding previous values
  const cumulativeRain = normalizedRainData.reduce<number[]>((acc, currentValue: any, index) => {
    const previousSum = index > 0 ? acc[index - 1] : 0;
    acc.push(previousSum + currentValue);
    return acc;
  }, []);

  console.log('normalizedRainData', normalizedRainData);

  return {
    rain: normalizedRainData, // Rainfall per hour
    cumulativeRain, // Progressive cumulative rainfall
    categories: Array.isArray(categories) ? categories : categories.categories, // Hours of the day
  };
};

export default prepareData;
