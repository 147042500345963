import React from 'react';
import {
  Grid,
  Typography,
  Link,
  TypographyVariant,
  Box,
} from '@mui/material';

function CardHeader({
  title, ActionButton, typographyVariant, additionalLink,
}: {
  title: string,
  ActionButton?: JSX.Element;
  typographyVariant?: TypographyVariant;
  additionalLink?: string;
}) {
  const parsedTitle = title.split('/').map((elem, index) => (
    <React.Fragment key={index}>
      {elem.trim()}
    </React.Fragment>
  ));

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={1}
      alignItems="center"
    >
      <Grid item>
        <Box display="flex" alignItems="center">
          <Typography
            variant={typographyVariant ?? 'h4'}
            color="primary"
            gutterBottom
            display="inline"
          >
            {parsedTitle}
          </Typography>

          {additionalLink && (
            <Typography variant="body1" color="textSecondary" display="inline" sx={{ marginLeft: 1 }}>
            <Link
              href={additionalLink}
              color="secondary"
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
            >
              Fuente de Información
            </Link>
          </Typography>
          )}
        </Box>
      </Grid>
      {ActionButton && (
        <Grid item>
          {ActionButton}
        </Grid>
      )}
    </Grid>
  );
}

export default CardHeader;
