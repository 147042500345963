import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import userReducer from './userReducer';
import { CombinedStateType } from '../types';
import userAccountReducer from './userAccountReducer';
import accountFarmReducer from './accountFarmReducer';
import farmSectorReducer from './farmSectorReducer';
import graphsFilterReducer from './graphsFilterReducer';

const rootReducer: Reducer<CombinedStateType> = combineReducers({
  account: accountReducer,
  userAccount: userAccountReducer,
  accountFarm: accountFarmReducer,
  farmSector: farmSectorReducer,
  form: formReducer,
  user: userReducer,
  graphsFilter: graphsFilterReducer,
});

export default rootReducer;
