import axios from 'axios';

export async function fetchFilteredReadingsFromAPI(filters: {

  farmId: string;
  startDate: string;
  endDate: string;
  sensorType: string;
  sectorId?: string;
  wiseconnZoneId?: number;
  hourInterval?: number;
  endpoint?: string;
  fetchingDate?: boolean;
  name?: string;
}) {
  const {
    farmId,
    startDate,
    endDate,
    sensorType,
    sectorId,
    wiseconnZoneId,
    hourInterval,
    endpoint,
    fetchingDate,
    name,
  } = filters;

  let baseEndpoint = '/api/readings';
  if (endpoint) {
    baseEndpoint += endpoint;
  }

  try {
    const response = await axios.get(baseEndpoint, {
      params: {
        farmId,
        startDate,
        endDate,
        sensorType,
        hourInterval,
        ...(sectorId && { sectorId }),
        ...(wiseconnZoneId && { wiseconnZoneId }),
        ...(fetchingDate && { fetchingDate }),
        ...(name && { name }),
      },
    });
    console.log('response.data.readings', response.data.readings);

    return response.data.readings || [];
  } catch (error) {
    console.error('Error fetching readings:', error);
    throw new Error('Failed to fetch readings');
  }
}

export async function fetchRawReadingsFromAPI(filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sensorType?: string;
  sectorId?: string;
  wiseconnZoneId?: number;
  hourInterval?: number;
  name?: string;
}) {
  const {
    farmId,
    startDate,
    endDate,
    sensorType,
    sectorId,
    wiseconnZoneId,
    name,
    hourInterval,
  } = filters;

  const baseEndpoint = '/api/readings/all';

  console.log(' fetching baseEndpoint', baseEndpoint);

  try {
    const response = await axios.get(baseEndpoint, {
      params: {
        farmId,
        startDate,
        endDate,
        sensorType,
        ...(sectorId && { sectorId }),
        ...(wiseconnZoneId && { wiseconnZoneId }),
        ...(name && { name }),
        ...(hourInterval && { hourInterval }),
      },
    });

    return response.data.readings || [];
  } catch (error) {
    console.error('Error fetching readings:', error);
    throw new Error('Failed to fetch readings');
  }
}

export default {
  fetchFilteredReadingsFromAPI,
  fetchRawReadingsFromAPI,
};
