import React from 'react';
import {
  Box,
  Container,
  Grid,
} from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import Page from '../../../components/Layout/Page';
import useStyles from './styles';
import CustomHeader from '../../../components/General/CustomHeader';
import SectorsList from '../Layout/component/Sector/SectorsList';
import WeatherReport from './components/WeatherReport';
import WeatherForecast from './components/WeatherForecast';
import useFetchFarms from '../../../hooks/useFetchFarms';
import AccountHomeMap from './components/AccountHomeMap';

const AVAILABLE_LIBRARIES: ('drawing' | 'places')[] = ['drawing', 'places'];

const AccountHome = () => {
  const classes = useStyles({});
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries: AVAILABLE_LIBRARIES,
  });

  useFetchFarms();
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Page className={classes.root} title='Inicio'>
      <Container maxWidth={false} className={classes.containerContainer}>
        <CustomHeader title='Inicio'/>
        <Grid container spacing={3} style={{ height: '100%', paddingTop: '24px' }}>
          <Grid item xs={12} md={3}>
            <Box marginRight='24px' className={classes.lateralListsContainer}>
              <SectorsList />
            </Box>
          </Grid>

          <Grid item xs={12} md={9}>
            <AccountHomeMap />
            <WeatherReport />
            <WeatherForecast />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AccountHome;
