/* eslint-disable @typescript-eslint/default-param-last */
/* eslint no-param-reassign: ["error", { "props": false }] */
import produce, { Draft } from 'immer';
import { GraphFilterState, GraphsFilterCustomAction } from '../types';
import { SET_START_DATE, SET_END_DATE } from '../actions/graphsFilterActions';

// Helper functions
const getDefaultEndDate = () => {
  const now = new Date();
  return now.toISOString();
};

const getDefaultStartDate = () => {
  const now = new Date();
  now.setDate(now.getDate() - 7);
  now.setUTCHours(0, 0, 0, 0);
  return now.toISOString();
};

const formatStartDate = (date: string) => {
  const d = new Date(date);
  d.setUTCHours(0, 0, 0, 0);
  return d.toISOString();
};

const formatEndDate = (date: string) => {
  const todayDateToFormat = new Date(date);
  const today = new Date();

  // Si la fecha es hoy, usamos la hora actual, en caso contrario, usamos la hora 23:59:59
  if (todayDateToFormat.toDateString() === today.toDateString()) {
    return new Date().toISOString();
  }
  todayDateToFormat.setUTCHours(23, 59, 59, 999);
  return todayDateToFormat.toISOString();
};

const initialState: GraphFilterState = {
  startDate: getDefaultStartDate(),
  endDate: getDefaultEndDate(),
};

const dateFilterReducer = (
  state = initialState,
  action: GraphsFilterCustomAction,
): GraphFilterState => {
  switch (action.type) {
    case SET_START_DATE: {
      return produce(state, (draft: Draft<GraphFilterState>) => {
        draft.startDate = formatStartDate(action.payload);
      });
    }

    case SET_END_DATE: {
      return produce(state, (draft: Draft<GraphFilterState>) => {
        draft.endDate = formatEndDate(action.payload);
      });
    }

    default:
      return state;
  }
};

export default dateFilterReducer;
