import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Air as WindIcon,
  Navigation as WindDirectionIcon,
  WbSunny as SolarRadiationIcon,
  Opacity as HumidityIcon,
  DeviceThermostat as TemperatureIcon,
  TrendingUp as MaxTemperatureIcon,
  TrendingDown as MinTemperatureIcon,
  Umbrella as DailyRainIcon,
  Grain as RainHourIcon,
  Nature as EtoIcon,
} from '@mui/icons-material/';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import useStyles from '../styles';
import CardHeader from '../../../../components/General/CardHeader';

import { WeatherReportData, fecthWeatherReport } from '../utils';
import { ReduxState } from '../../../../types';

const WeatherReport = () => {
  const classes = useStyles();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const [weatherData, setWeatherData] = useState<WeatherReportData | null>(null);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const localLatitude = Number(selectedFarm?.center?.lat);
  const localLongitude = Number(selectedFarm?.center?.lng);

  const fetchWeatherData = async () => {
    try {
      if (localLatitude || localLongitude) {
        setLoading(true);
        const updatedDate = new Date().toLocaleString();
        const weatherInformation = await fecthWeatherReport(localLatitude, localLongitude);

        setLastUpdated(updatedDate);
        setWeatherData(weatherInformation);
      }
    } catch (error) {
      enqueueSnackbar('Error al actualizar el reporte del clima', { variant: 'error' });
      setLastUpdated('Error - Intenta nuevamente');
      setWeatherData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWeatherData();
  }, [selectedFarm]);

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <>
        <CardHeader
          title='Clima actual'
          ActionButton={
            <Box display="flex" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="flex-end" marginRight={1}>
                <Typography variant="body2" className={classes.lastUpdatedText}>
                  Última actualización:
                </Typography>
                <Typography variant="body2" className={classes.lastUpdatedDate}>
                  {lastUpdated || 'N/A'}
                </Typography>
              </Box>
              <IconButton onClick={fetchWeatherData} color="primary">
                <RefreshIcon />
              </IconButton>
            </Box>
          }
          typographyVariant="h3"
        />
        <Divider className={classes.divider} />
        {weatherData !== null && (
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <WindIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Velocidad viento: <strong>{weatherData?.windSpeed != null ? `${weatherData?.windSpeed} Km/h` : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <WindDirectionIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Dirección viento: <strong>{weatherData?.windDirection !== null ? weatherData?.windDirection : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <SolarRadiationIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Radiación solar: <strong>{weatherData?.solarRadiation !== null ? weatherData?.solarRadiation : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <HumidityIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Humedad ambiente: <strong>{weatherData?.humidity !== null ? `${weatherData?.humidity} ml` : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <TemperatureIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Temperatura ambiente: <strong>{weatherData?.temperature !== null ? `${weatherData?.temperature}°C` : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <MaxTemperatureIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Temperatura máxima: <strong>{weatherData?.maxTemperature !== null ? `${weatherData?.maxTemperature}°C` : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <MinTemperatureIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Temperatura mínima: <strong>{weatherData?.minTemperature !== null ? `${weatherData?.minTemperature}°C` : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <DailyRainIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Lluvia día: <strong>{weatherData?.dailyRain !== null ? `${weatherData?.dailyRain} mm` : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <RainHourIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Lluvia hora: <strong>{weatherData?.hourlyRain !== null ? `${weatherData?.hourlyRain} mm` : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" alignItems="center">
                <EtoIcon fontSize='medium'/>
                <Typography variant="body1" marginLeft={1}>
                  Eto día: <strong>{weatherData?.eto !== null ? weatherData?.eto : 'No disponible'}</strong>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        )}
      </>
    );
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        {!selectedFarm ? (
          <>
            <Typography
              variant='h3'
              color="primary"
              gutterBottom
              display="inline"
            >
              Clima actual
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.noFarmSelectedText}>
              Elige un campo para ver el reporte del clima
            </Typography>
          </>
        ) : (
          renderContent()
        )}
      </CardContent>
    </Card>
  );
};

export default WeatherReport;
