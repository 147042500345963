import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { ReduxState } from '../../../types';

// To Do: Actualizar types
interface ChartDataType {
  series: { type: string; name: string; data: any[] }[];
  categories: string[];
}

function RainfallChart({
  sectorId,
  farmId,
  hideFilter = true,
}: {
  sectorId: string
  farmId: string
  hideFilter?: boolean
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
  });

  const title = 'Gráfico de Precipitaciones';
  const yAxisTitle = 'Precipitaciones (mm)';

  // const farmId = '670018b97f939bc8cb0352d5';
  // const [startDate, setStartDate] = useState(new Date('2024-11-24T00:00:00.000Z').toISOString());
  // const [endDate, setEndDate] = useState(new Date('2024-11-26T23:59:59.000Z').toISOString());
  // const wiseconnZoneId = '107381';
  // const sectorId = '';
  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);

  // To Do: Actualizar types
  const fetchData = async (filters: { startDate: string; endDate: string }) => {
    const preparedFilters: any = {
      farmId,
      startDate: filters.startDate,
      endDate: filters.endDate,
    };
    // if (sectorId) { ver si es necesario
    //   preparedFilters.sectorId = sectorId;
    // }
    // if (wiseconnZoneId) {
    //   chartFilters.wiseconnZoneId = wiseconnZoneId;
    // } Creo que no es necesario
    const preparedData = await prepareData(preparedFilters);
    console.log('preparedData', preparedData);

    const seriesData = [
      {
        type: 'bar',
        name: 'Rainfall (mm)',
        data: preparedData.rain,
        yAxis: 0,
      },
      {
        type: 'line',
        name: 'Accumulated Rainfall (mm)',
        data: preparedData.cumulativeRain,
        yAxis: 0,
      },
    ];

    setChartData({
      series: seriesData,
      categories: preparedData.categories,
    });
  };

  // Llamada inicial al cargar el componente
  useEffect(() => {
    fetchData({ startDate, endDate });
  }, [startDate, endDate]);

  const customTooltip = {
    shared: true,
    useHTML: true,
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      // const date = new Date(this.x as string).toLocaleDateString();
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${points}</div>`;
    },
  };

  return (
    <Box>
      <BaseHighchart
        title={title}
        categories={chartData.categories}
        yAxisTitle={yAxisTitle}
        series={chartData.series as Highcharts.SeriesOptionsType[]}
        tooltip={customTooltip}
        hideFilter={hideFilter}
        sectorId={sectorId}
      />
    </Box>
  );
}

export default RainfallChart;
