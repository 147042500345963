import React, { useEffect, useState } from 'react';
import {
  Box, Chip, Divider, Grid, MenuItem, TextField, Typography,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useStyles from './styles';
import { ReduxState } from '../../types';
import { setStartDate, setEndDate } from '../../actions/graphsFilterActions';
import { CultivationCyclePopulatedSchema, GetCultivationCyclesBySectorResponse, PhenologicalStateWithId } from '../../requests/api/apiTypes';
import useFetchApi from '../../hooks/useFetchApi';

type TimeInterval = '1-day' | '1-week' | '2-weeks' | '1-month' | '3-months' | '6-months' | null;

type CultivationCycleWithUUID = Omit<CultivationCyclePopulatedSchema, 'phenologicalStates'> & {
  phenologicalStates: PhenologicalStateWithId[];
};

function ChartFilter({ sectorId }: { sectorId: string }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const [selectedInterval, setSelectedInterval] = useState<TimeInterval>(null);
  const [cultivationCycles, setCultivationCycles] = useState<CultivationCycleWithUUID[]>([]);
  const [selectedCycleId, setSelectedCycleId] = useState<string>('default');
  const [selectedStateId, setSelectedStateId] = useState<string>('default');

  const { data } = useFetchApi<GetCultivationCyclesBySectorResponse>({
    modelEndpoint: 'cultivationCycleSectorDetail',
    modelName: 'cultivationCycleSector',
    id: sectorId,
    dependencies: [sectorId],
  });

  useEffect(() => {
    if (data) {
      const cyclesWithStateIds = data.cultivationCycles.map((cycle) => ({
        ...cycle,
        phenologicalStates: cycle.phenologicalStates.map((state) => ({
          ...state,
          uuid: uuidv4(), // Añadimos un id único a cada estado
        })),
      }));
      setCultivationCycles(cyclesWithStateIds);
    }
  }, [data]);

  const resetSelectors = () => {
    setSelectedCycleId('default');
    setSelectedStateId('default');
  };

  const handleStartDateChange = (date: string) => {
    setSelectedInterval(null);
    resetSelectors();
    dispatch(setStartDate(date));
  };

  const handleEndDateChange = (date: string) => {
    setSelectedInterval(null);
    resetSelectors();
    dispatch(setEndDate(date));
  };

  const handleIntervalSelect = (interval: TimeInterval) => {
    setSelectedInterval(interval);
    resetSelectors();

    const intervalEndDate = new Date();
    const intervalStartDate = new Date();

    switch (interval) {
      case '1-day':
        intervalStartDate.setDate(intervalEndDate.getDate() - 1);
        break;
      case '1-week':
        intervalStartDate.setDate(intervalEndDate.getDate() - 7);
        break;
      case '2-weeks':
        intervalStartDate.setDate(intervalEndDate.getDate() - 14);
        break;
      case '1-month':
        intervalStartDate.setMonth(intervalEndDate.getMonth() - 1);
        break;
      case '3-months':
        intervalStartDate.setMonth(intervalEndDate.getMonth() - 3);
        break;
      case '6-months':
        intervalStartDate.setMonth(intervalEndDate.getMonth() - 6);
        break;
      default:
        return;
    }

    dispatch(setStartDate(intervalStartDate.toISOString()));
    dispatch(setEndDate(intervalEndDate.toISOString()));
  };

  const setDatesOnCultivationCycle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cycleId = event.target.value;
    setSelectedCycleId(cycleId);
    setSelectedStateId('default');
    setSelectedInterval(null);

    if (cycleId === 'default') return;

    const selectedCycle = cultivationCycles.find((cycle) => cycle.id === cycleId);
    if (selectedCycle) {
      dispatch(setStartDate(selectedCycle.startDate));
      dispatch(setEndDate(selectedCycle.endDate));
    }
  };

  const setDatesOnPhenologicalState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stateId = event.target.value;
    setSelectedStateId(stateId);
    setSelectedInterval(null);

    if (stateId === 'default') return;

    const selectedCycle = cultivationCycles.find((cycle) => cycle.id === selectedCycleId);
    const selectedState = selectedCycle?.phenologicalStates.find((state) => state.uuid === stateId);

    if (selectedState) {
      const today = new Date();
      const currentYear = today.getFullYear();

      const [startMonth, startDay] = selectedState.startDate.split('-').map(Number);
      const startDateToSet = new Date(currentYear, startMonth - 1, startDay);

      // If start date hasn't occurred this year, use previous year
      if (startDateToSet > today) {
        startDateToSet.setFullYear(currentYear - 1);
      }

      // Parse end date (MM-DD)
      const [endMonth, endDay] = selectedState.endDate.split('-').map(Number);
      const endDateToSet = new Date(startDateToSet.getFullYear(), endMonth - 1, endDay);

      // Calculate if we need to add a year to the end date
      if (endDateToSet < startDateToSet) {
        endDateToSet.setFullYear(endDateToSet.getFullYear() + 1);
      }

      dispatch(setStartDate(startDateToSet.toISOString()));
      dispatch(setEndDate(endDateToSet.toISOString()));
    }
  };

  const selectedCycle = cultivationCycles.find((cycle) => cycle.id === selectedCycleId);

  return (
    <Box>
      <Divider className={classes.divider}/>
      <Grid container spacing={2}>
        <Grid item sm={12} md={3}>
          <Box className={classes.selectFieldsContainer}>
            <TextField
              className={classes.selectField}
              label="Ciclo de cultivo"
              value={selectedCycleId}
              select
              onChange={setDatesOnCultivationCycle}
              variant="standard"
              disabled={cultivationCycles.length === 0}
            >
              <MenuItem value="default">Seleccionar</MenuItem>
              {cultivationCycles.map((cycle) => (
                <MenuItem key={cycle.id} value={cycle.id}>
                  {cycle.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              className={classes.selectField}
              label="Estado fenológico"
              value={selectedStateId}
              select
              onChange={setDatesOnPhenologicalState}
              variant="standard"
              disabled={!selectedCycle || selectedCycleId === 'default'}
            >
              <MenuItem value="default">Seleccionar</MenuItem>
              {selectedCycle?.phenologicalStates.map((state) => (
                <MenuItem key={state.uuid} value={state.uuid}>
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>

        <Grid item sm={12} md={5}>
          <Typography className={classes.chipsLabel}>
            Intervalos de tiempo
          </Typography>
          <Box className={classes.chipsContainer}>
            <Chip
              key='1-day'
              color={selectedInterval === '1-day' ? 'primary' : undefined}
              label="1 día"
              onClick={() => handleIntervalSelect('1-day')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='1-week'
              color={selectedInterval === '1-week' ? 'primary' : undefined}
              label="1 semana"
              onClick={() => handleIntervalSelect('1-week')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='2-weeks'
              color={selectedInterval === '2-weeks' ? 'primary' : undefined}
              label="2 semanas"
              onClick={() => handleIntervalSelect('2-weeks')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='1-month'
              color={selectedInterval === '1-month' ? 'primary' : undefined}
              label="1 mes"
              onClick={() => handleIntervalSelect('1-month')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='3-months'
              color={selectedInterval === '3-months' ? 'primary' : undefined}
              label="3 meses"
              onClick={() => handleIntervalSelect('3-months')}
              size='small'
              className={classes.layoutMapChip}
            />
            <Chip
              key='6-months'
              color={selectedInterval === '6-months' ? 'primary' : undefined}
              label="6 meses"
              onClick={() => handleIntervalSelect('6-months')}
              size='small'
              className={classes.layoutMapChip}
            />
          </Box>
        </Grid>

        <Grid item sm={12} md={4}>
          <Box className={classes.selectFieldsContainer}>
            <TextField
              className={classes.selectField}
              label="Fecha de inicio"
              type="date"
              value={startDate.split('T')[0]}
              onChange={(e) => handleStartDateChange(e.target.value)}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className={classes.selectField}
              label="Fecha de fin"
              type="date"
              value={endDate.split('T')[0]}
              onChange={(e) => handleEndDateChange(e.target.value)}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
            <Box className={classes.commentIcon}>
              <CommentIcon />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider className={classes.divider}/>
    </Box>
  );
}

export default ChartFilter;
