import {
  fetchFilteredReadingsFromAPI,
  fetchRawReadingsFromAPI,
} from '../../services/readingsService';
import {
  calculateEsTmax,
  calculateEsTmin,
  calculateEa,
  calculateRnl,
  calculateRns,
  calculateRn,
  calculateEs,
  calculateS,
  calculateET0,
} from './formulas';

// To Do: Actualizar types
export const getFilteredReadings = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sensorType: string;
  wiseconnZoneId?: number;
  sectorId?: string;
  hourInterval?: number;
  endpoint?: string;
}) => {
  // Fetch readings from API
  const readings = await fetchFilteredReadingsFromAPI({
    farmId: filters.farmId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    wiseconnZoneId: filters.wiseconnZoneId,
    sensorType: filters.sensorType,
    sectorId: filters.sectorId,
    hourInterval: filters.hourInterval,
    endpoint: filters.endpoint,
  });

  return readings;
};

// The difference from getFilteredReadings is that this function returns
// all the readings for day without removing duplicates,
// and it's used for Irrigation Chart
export const getAllDailyReadings = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sensorType?: string;
  wiseconnZoneId?: number;
  sectorId?: string;
  hourInterval?: number;
  name?: string;
}) => {
  // Fetch readings from API
  const readings = await fetchRawReadingsFromAPI({
    farmId: filters.farmId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    wiseconnZoneId: filters.wiseconnZoneId,
    sensorType: filters.sensorType,
    sectorId: filters.sectorId,
    hourInterval: filters.hourInterval,
    name: filters.name,
  });

  return readings;
};

export const getET0 = async (filters: { // Este valor no se usa
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: number;
  endpoint?: string;
  hourInterval?: number;
}) => {
  // Ejecutamos todas las llamadas necesarias concurrentemente
  const [
    minAndMaxTemperature,
    minAndMaxHumidity,
    hourlySolarRadiation,
    hourlyWindVelocity,
  ] = await Promise.all([
    getFilteredReadings({
      ...filters,
      sensorType: 'Temperature',
      endpoint: '/daily',
      hourInterval: 24,
    }),
    getFilteredReadings({
      ...filters,
      sensorType: 'Humidity',
      endpoint: '/daily',
      hourInterval: 24,
    }),
    getFilteredReadings({
      ...filters,
      sensorType: 'Solar Radiation',
      endpoint: '/daily',
      hourInterval: 24,
    }),
    getFilteredReadings({
      ...filters,
      sensorType: 'Wind Velocity',
      endpoint: '/daily',
      hourInterval: 24,
    }),
  ]);
  // Calcular ET0 por hora

  const calculatedET0 = minAndMaxTemperature.map((item: any, index: number) => {
    const maxTemperature = item.maxValue;
    const minTemperature = item.minValue;
    const maxHumidity = minAndMaxHumidity[index].maxValue;
    const minHumidity = minAndMaxHumidity[index].minValue;
    const solarRadiation = hourlySolarRadiation[index].averageValue;
    const windVelocity = hourlyWindVelocity[index].averageValue;
    // Validamos que todos los valores estén presentes
    if (
      maxTemperature === undefined
      || minTemperature === undefined
      || maxHumidity === undefined
      || minHumidity === undefined
      || solarRadiation === undefined
      || windVelocity === undefined
    ) {
      console.warn(`Missing data for hour ${index}: ET0 set to 0.`);
      // To Do: Ver que hacer con los datos faltantes
      return 0; // Si falta algún dato, asignamos ET0 como 0
    }

    // Cálculos intermedios
    const esTmax = calculateEsTmax(maxTemperature);
    const esTmin = calculateEsTmin(minTemperature);
    const ea = calculateEa(esTmax, esTmin, minHumidity, maxHumidity);
    const rns = calculateRns(solarRadiation);
    const rnl = calculateRnl(maxTemperature, minTemperature, ea, solarRadiation);
    const rn = calculateRn(rns, rnl);
    const es = calculateEs(esTmax, esTmin);
    const averageTemperature = (maxTemperature + minTemperature) / 2;
    const s = calculateS(es, averageTemperature);

    // Cálculo final de ET0
    return calculateET0(s, rn, averageTemperature, windVelocity, es, ea);
  });

  // Calcular el acumulado progresivo de ET0
  let cumulativeET0 = 0;
  const accumulatedEt0 = calculatedET0.map((et0: number, index: number) => {
    cumulativeET0 += et0;
    return {
      et0,
      cumulativeET0,
      date: minAndMaxTemperature[index].date,
    };
  });

  return accumulatedEt0;
};
