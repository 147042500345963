import React, { ChangeEvent, useEffect, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Autocomplete, Box, Divider, IconButton, TextField, Typography,
} from '@mui/material';
import { format } from 'rut.js';
import useStyles from '../styles';
import CustomButton from '../../../components/General/CustomButton';
import useFetchApi from '../../../hooks/useFetchApi';
import { CompanySchema, GetAccountByIdResponse, GetCompaniesPerPageResponse } from '../../../requests/api/apiTypes';

const UpsertModalContent = ({
  handleCloseModal,
  handleUpsertFunction,
  id,
  isLoadingUpsert,
}: {
  handleCloseModal: VoidFunction,
  handleUpsertFunction: ({
    name,
    companies,
    id,
  }: {
    name: string,
    companies: Pick<CompanySchema, 'id' | 'businessName' | 'rut'>[],
    id?: string,
  }) => void,
  id?: string,
  isLoadingUpsert: boolean,
}) => {
  const classes = useStyles();
  const [localAccount, setLocalAccount] = useState<{
    name: string,
    companies: Pick<CompanySchema, 'id' | 'businessName' | 'rut'>[],
    beenUpdated: boolean,
  }>({
    name: '',
    companies: [],
    beenUpdated: false,
  });
  const [error, setError] = useState({ active: false, message: '' });

  const entityToModify = id && useFetchApi<GetAccountByIdResponse>({
    modelName: 'accounts',
    modelEndpoint: 'accountsDetail',
    id,
  });

  const { data } = useFetchApi<GetCompaniesPerPageResponse>({
    modelName: 'companies',
    modelEndpoint: 'companiesList',
  });

  useEffect(() => {
    if (id && entityToModify && entityToModify.data && !localAccount.beenUpdated) {
      setLocalAccount({
        beenUpdated: true,
        name: entityToModify.data.account ? entityToModify.data.account.name : '',
        companies: (entityToModify.data.account && entityToModify.data.account.companies)
          ? entityToModify.data
            .account.companies.map((elem: CompanySchema) => ({
              id: elem.id,
              businessName: elem.businessName,
              rut: elem.rut,
            }))
          : [],
      });
    }
  }, [entityToModify]);

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | null,
    source: string,
    value?: Pick<CompanySchema, 'id' | 'businessName' | 'rut'>[],

  ) => {
    if (source === 'businessName') {
      if (error.active) {
        setError({ active: false, message: '' });
      }
      setLocalAccount(
        (prevState) => (
          {
            ...prevState,
            [source]: (e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)
              .target.value,
          }),
      );
    } else if (source === 'companies') {
      setLocalAccount(
        (prevState) => (
          { ...prevState, [source]: value || [] }),
      );
    } else {
      setLocalAccount(
        (prevState) => (
          { ...prevState, [source]: e?.target.value }),
      );
    }
  };

  const handleUpsertAccount = () => {
    if (!localAccount.name || localAccount.name.trim() === '') {
      setError({ active: true, message: 'Nombre no puede estar vacio' });
      return;
    }
    handleUpsertFunction({ name: localAccount.name, companies: localAccount.companies, id });
  };

  return (
    <Box className={classes.upsertAccountModalContainer}>
      <Box className={classes.upsertAccountModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertAccountModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            {id ? 'Editar cuenta' : 'Agregar cuenta'}
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.dividerStyle} />
      <TextField
        name='company'
        label="Nombre"
        error={error.active}
        helperText={error.active && error.message}
        variant='standard'
        value={localAccount.name}
        onChange={(e) => handleChange(e, 'name')}
      />
      <Autocomplete
        id="account-autocomplete"
        multiple
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={data?.companies || []}
        size='small'
        className={classes.upsertAccountModalAutocomplete}
        getOptionLabel={(option) => `${option.businessName} - ${format(option.rut)}`}
        value={localAccount.companies}
        onChange={(e, value) => handleChange(null, 'companies', value)}
        renderInput={(params) => <TextField {...params} label="Empresas" size='small' />}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {`${option.businessName} - ${format(option.rut)}`}
          </li>
        )}
      />
      <Box className={classes.upsertAccountModalButtonsContainer}>
        <Box width='100%' mr={2}><CustomButton color='blue' buttonText='Cancelar' onClick={handleCloseModal} /></Box>
        <Box width='100%' ml={2}>
          <CustomButton
            color='green'
            buttonText={id ? 'Editar' : 'Agregar'}
            onClick={handleUpsertAccount}
            disabled={isLoadingUpsert}
            setLoadingSpinnerWhenDisabled={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UpsertModalContent;
