import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';

import {
  SegmentPoint,
  ChartDataType,
  ChartFilters,
} from '../types';

import type PreparedData from './types';
import { ReduxState } from '../../../types';

function StackedSoilMoistureChart({
  sectorId,
  farmId,
  hideFilter = true,
}: {
  sectorId: string
  farmId: string
  hideFilter?: boolean
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
  });

  const title = 'Gráfico de Humedad del Suelo';
  const yAxisTitle = 'Humedad del Suelo (%)';

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  // Creo que no es necesario const wiseconnZoneId = '';

  const decreaseSegments: SegmentPoint[] = [];
  const increaseSegments: SegmentPoint[] = [];

  const fetchData = async (filters: { startDate: string; endDate: string }) => {
    const chartFilters: ChartFilters = {
      farmId,
      startDate: filters.startDate,
      endDate: filters.endDate,
    };
    if (sectorId) {
      chartFilters.sectorId = sectorId;
    }

    // if (wiseconnZoneId) {
    //   chartFilters.wiseconnZoneId = wiseconnZoneId;
    // } Creo que no es necesario
    const preparedData: PreparedData = await prepareData(chartFilters);

    // Transform data into x,y point format
    const seriesData = preparedData.series.map((item: any) => ({
      type: 'line',
      name: item.name,
      data: item.data.map((value: number, index: number, array: number[]) => {
        const previousValue = array[index - 1] || value; // Get the previous value
        const isSignificantDecrease = previousValue && value < previousValue * 0.95; // 5% decrease
        const isSignificantIncrease = previousValue && value > previousValue * 1.05; // 5% increase

        if (isSignificantDecrease) {
          // Add start and end points for the decreasing segment
          decreaseSegments.push(
            { x: index - 1, y: previousValue }, // Start of the decrease
            { x: index, y: value }, // End of the decrease
            { x: index, y: undefined }, // Break the line after the segment
          );
        }

        if (isSignificantIncrease) {
          // Add start and end points for the increasing segment
          increaseSegments.push(
            { x: index - 1, y: previousValue }, // Start of the increase
            { x: index, y: value }, // End of the increase
            { x: index, y: undefined }, // Break the line after the segment
          );
        }

        return {
          x: index,
          y: value,
          custom: {
            date: preparedData.categoriesByIndex[index],
            startInterval: preparedData.intervalStartHours[index],
            endInterval: preparedData.intervalEndHours[index],
          },
        };
      }),
    }));

    // Create the secondary series for the decrease segments
    const decreaseSeries = {
      type: 'line',
      name: 'Significant Decrease',
      data: decreaseSegments,
      color: 'rgba(255, 0, 0, 0.3)',
      lineWidth: 10,
      dashStyle: 'Solid',
      enableMouseTracking: false,
      showInLegend: false, // Hide from legend
    };

    // Create the secondary series for the increase segments
    const increaseSeries = {
      type: 'area',
      name: 'Significant Increase',
      data: increaseSegments,
      color: 'rgba(128, 0, 128, 0.3)', // Semi-transparent purple
      fillOpacity: 0.3,
      lineWidth: 0,
      enableMouseTracking: false,
      threshold: null, // Makes area fill from top
      showInLegend: false, // Hide from legend
    };

    setChartData({
      series: [...seriesData, decreaseSeries, increaseSeries],
      categories: preparedData.categories as string[],
    });
  };

  // Llamada inicial al cargar el componente
  useEffect(() => {
    fetchData({ startDate, endDate });
  }, [startDate, endDate]);

  const customTooltip = {
    shared: true,
    useHTML: true,
    formatter(
      this: Highcharts.TooltipFormatterContextObject,
    ) {
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${this.point?.custom?.date}</br> (${this.point?.custom?.startInterval})</div>`;
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${subHeader}${points}</div>`;
    },
  };

  return (
    <div>
      <BaseHighchart
        title={title}
        categories={chartData.categories}
        yAxisTitle={yAxisTitle}
        series={chartData.series as Highcharts.SeriesOptionsType[]}
        tooltip={customTooltip}
        sectorId={sectorId}
        hideFilter={hideFilter}
        // tooltipText="Tooltip de ejemplo"
      />
    </div>
  );
}

export default StackedSoilMoistureChart;
