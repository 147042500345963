import { fetchWeatherApi } from 'openmeteo';

export type WeatherReportData = {
  windSpeed: number | null,
  windDirection: string | null,
  solarRadiation: number | null,
  humidity: number | null,
  temperature: number | null,
  maxTemperature: number | null,
  minTemperature: number | null,
  dailyRain: number | null,
  hourlyRain: number | null,
  eto: number | null,
};

export type WeatherForecastData = {
  day: string | null;
  date: string | null;
  weather: string;
  minTemperature: number | null;
  maxTemperature: number | null;
  RainMm: number | null;
  RainProbability: number | null;
  eto: number | null;
};

export type Forecast = {
  [key: number]: WeatherForecastData;
};

// Al leer la response, el id de los parámetros es la posición de la variable en el arreglo.
const weatherReportParams = {
  current: ['temperature_2m', 'relative_humidity_2m', 'wind_speed_10m', 'wind_direction_10m', 'rain'],
  daily: [
    'weather_code',
    'temperature_2m_min',
    'temperature_2m_max',
    'precipitation_sum',
    'precipitation_probability_max',
    'et0_fao_evapotranspiration',
    'uv_index_max',
  ],
  timezone: 'America/Sao_Paulo',
  forecast_days: 8,
};

const weatherUrl = 'https://api.open-meteo.com/v1/forecast';

const windDegreesToDirection = (degrees: number | null) => {
  if (degrees === null) return null;

  // Por si acaso el valor es negativo o mayor a 360
  const normalizedDegree = ((degrees % 360) + 360) % 360;

  if (normalizedDegree >= 337.5 || normalizedDegree < 22.5) return 'Norte';
  if (normalizedDegree >= 22.5 && normalizedDegree < 67.5) return 'Noroeste';
  if (normalizedDegree >= 67.5 && normalizedDegree < 112.5) return 'Oeste';
  if (normalizedDegree >= 112.5 && normalizedDegree < 157.5) return 'Suroeste';
  if (normalizedDegree >= 157.5 && normalizedDegree < 202.5) return 'Sur';
  if (normalizedDegree >= 202.5 && normalizedDegree < 247.5) return 'Sureste';
  if (normalizedDegree >= 247.5 && normalizedDegree < 292.5) return 'Este';
  return 'Noreste';
};

const roundToOneDecimal = (value: number | null) => (
  value !== null ? parseFloat(value.toFixed(1)) : null
);

// Consultar documentación de la API para entender los códigos de clima
const getWeatherDescription = (code: number | null): string => {
  if (code === null) return 'N/A';

  switch (code) {
    case 0:
      return 'Soleado';
    case 1:
    case 2:
    case 3:
      return 'Nublado';
    case 45:
    case 48:
      return 'Neblina';
    case 51:
    case 53:
    case 55:
    case 56:
    case 57:
      return 'Llovizna';
    case 61:
    case 63:
    case 65:
    case 66:
    case 67:
      return 'Lluvioso';
    case 71:
    case 73:
    case 75:
      return 'Nieve';
    case 77:
      return 'Granizo';
    case 80:
    case 81:
    case 82:
      return 'Chubascos';
    case 85:
    case 86:
      return 'Nieve';
    case 95:
      return 'Tormenta';
    default:
      return 'N/A';
  }
};

export const fecthWeatherReport = async (localLatitude: number, localLongitude: number) => {
  const updatedWeatherReportParams = {
    ...weatherReportParams,
    latitude: localLatitude,
    longitude: localLongitude,
  };

  const responses = await fetchWeatherApi(weatherUrl, updatedWeatherReportParams);
  const response = responses[0];

  const current = response.current();
  const daily = response.daily();

  // dailyRain y nextRainHour deberían venir directamente de la data de la estación de clima.
  const currentWeather = {
    temperature: roundToOneDecimal(current?.variables(0)?.value() ?? null),
    humidity: roundToOneDecimal(current?.variables(1)?.value() ?? null),
    windSpeed: roundToOneDecimal(current?.variables(2)?.value() ?? null),
    windDirection: windDegreesToDirection(current?.variables(3)?.value() ?? null),
    minTemperature: roundToOneDecimal(daily?.variables(1)?.valuesArray()?.[0] ?? null),
    maxTemperature: roundToOneDecimal(daily?.variables(2)?.valuesArray()?.[0] ?? null),
    dailyRain: roundToOneDecimal(daily?.variables(3)?.valuesArray()?.[0] ?? null),
    eto: roundToOneDecimal(daily?.variables(5)?.valuesArray()?.[0] ?? null),
    hourlyRain: roundToOneDecimal(current?.variables(4)?.value() ?? null),
    solarRadiation: roundToOneDecimal(daily?.variables(6)?.valuesArray()?.[0] ?? null),
  };

  return currentWeather;
};

export const fetchWeatherForecast = async (localLatitude: number, localLongitude: number) => {
  const filteredParams = weatherReportParams.daily.filter(
    (param) => param !== 'precipitation_hours' && param !== 'uv_index_max',
  );

  const forecastParams = {
    daily: filteredParams,
    timezone: weatherReportParams.timezone,
    forecast_days: weatherReportParams.forecast_days,
    latitude: localLatitude,
    longitude: localLongitude,
  };

  const responses = await fetchWeatherApi(weatherUrl, forecastParams);
  const response = responses[0];

  const daily = response.daily();

  const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sab'];
  const forecast: Forecast = {};

  for (let i = 0; i < 8; i += 1) {
    const date = new Date();
    date.setDate(date.getDate() + i);

    const day = daysOfWeek[date.getDay()];
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}`;

    forecast[i] = {
      day,
      date: formattedDate,
      minTemperature: roundToOneDecimal(daily?.variables(1)?.valuesArray()?.[i] ?? null),
      maxTemperature: roundToOneDecimal(daily?.variables(2)?.valuesArray()?.[i] ?? null),
      RainMm: roundToOneDecimal(daily?.variables(3)?.valuesArray()?.[i] ?? null),
      RainProbability: roundToOneDecimal(daily?.variables(4)?.valuesArray()?.[i] ?? null),
      eto: roundToOneDecimal(daily?.variables(5)?.valuesArray()?.[i] ?? null),
      weather: getWeatherDescription(daily?.variables(0)?.valuesArray()?.[i] ?? null),
    };
  }

  return forecast;
};
