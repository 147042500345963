import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

const MAX_DAYS_FOR_DAILY = 21;

function getGranularity(startDate: string, endDate: string): 'hourly' | 'daily' | 'weekly' {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  // Calcular la diferencia en días
  const diffInDays = end.diff(start, 'day', true);

  if (diffInDays <= 1) {
    return 'hourly';
  }
  if (diffInDays <= MAX_DAYS_FOR_DAILY) {
    return 'daily';
  }
  return 'weekly';
}

function buildCategoriesWithInterval(
  data: any[],
  hourInterval: number,
) {
  const uniqueDates = [...new Set(data.map((item: any) => item.date))];

  const categories = uniqueDates.flatMap((date) => [
    dayjs(date as string).format('DD MMM'), // Format date to DD MMM
    ...Array((24 / hourInterval) - 1).fill(''), // Add empty strings based on (24h/hourInterval - 1)
  ]);

  const categoriesByIndex = uniqueDates.flatMap((date) => [
    date,
    ...Array((24 / hourInterval) - 1).fill(date),
  ]);

  const intervalStartHours = uniqueDates.flatMap(() => {
    const intervals = [];
    for (let hour = 0; hour < 24; hour += hourInterval) {
      intervals.push(`${hour.toString().padStart(2, '0')}:00`);
    }
    return intervals;
  });

  const intervalEndHours = uniqueDates.flatMap(() => {
    const intervals = [];
    for (let hour = hourInterval; hour <= 24; hour += hourInterval) {
      intervals.push(`${hour.toString().padStart(2, '0')}:00`);
    }
    return intervals;
  });

  return {
    categories,
    categoriesByIndex,
    intervalStartHours,
    intervalEndHours,
  };
}

function buildCategories(
  granularity: 'hourly' | 'daily' | 'weekly',
  startDate: string,
  endDate: string,
  hourInterval?: number,
) {
  // Convert start/end dates to dayjs objects for easier manipulation
  const start = dayjs(startDate).utc();
  const end = dayjs(endDate).utc();
  const uniqueDates: string[] = [];

  // const hourlyInterval = hourInterval || 24;

  // Build array of all dates between start and end
  let current = start;
  while (current.isSameOrBefore(end)) {
    uniqueDates.push(current.format('YYYY-MM-DD'));
    current = current.add(1, 'day');
  }

  // Special handling for hourly data with intervals
  if (granularity === 'daily' && hourInterval) {
    // For each date, show the date once followed by empty strings
    // This creates proper spacing in the x-axis labels
    const categories = uniqueDates.flatMap((date) => [
      dayjs(date).utc().format('MMM DD'),
      ...Array((24 / hourInterval) - 1).fill(''),
    ]);

    // Keep track of which date each data point belongs to
    const categoriesByIndex = uniqueDates.flatMap((date) => [
      date,
      ...Array((24 / hourInterval) - 1).fill(date),
    ]);

    // Generate start times for each interval (e.g. "00:00", "04:00", "08:00" etc)
    const intervalStartHours = uniqueDates.flatMap(() => {
      const intervals = [];
      for (let hour = 0; hour < 24; hour += hourInterval) {
        intervals.push(`${hour.toString().padStart(2, '0')}:00`);
      }
      return intervals;
    });

    // Generate end times for each interval (e.g. "04:00", "08:00", "12:00" etc)
    const intervalEndHours = uniqueDates.flatMap(() => {
      const intervals = [];
      for (let hour = hourInterval; hour <= 24; hour += hourInterval) {
        intervals.push(`${hour.toString().padStart(2, '0')}:00`);
      }
      return intervals;
    });

    return {
      categories, // Display labels for x-axis
      categoriesByIndex, // Reference to which date each point belongs to
      intervalStartHours, // Start time of each interval
      intervalEndHours, // End time of each interval
    };
  }

  // For non-interval cases, format dates based on granularity
  if (granularity === 'hourly') {
    // Return array of hours from 00:00 to 23:00
    const categories = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);
    return categories;
  }

  if (granularity === 'daily') {
    // Format as "MMM-DD" for days (e.g. "Jan-01")
    return uniqueDates.map((date) => dayjs(date).utc().format('MMM DD'));
  }

  // Weekly case - also using "MMM-DD" format
  return uniqueDates.map((date) => dayjs(date).utc().format('MMM DD'));
}

export { getGranularity, buildCategories, buildCategoriesWithInterval };
