import React from 'react';
import SoilMoistureChart from '../../../../../../components/Highcharts/SoilMoistureChart/SoilMoistureChart';
import StackedSoilMoistureChart from '../../../../../../components/Highcharts/StackedSoilMoistureChart/StackedSoilMoistureChart';

const SoilGraphsTab = ({ sectorId, farmId }: { sectorId: string, farmId: string }) => (
    <>
      <SoilMoistureChart
        sectorId={sectorId}
        farmId={farmId}
      />
      <StackedSoilMoistureChart
        sectorId={sectorId}
        farmId={farmId}
      />
    </>
);

export default SoilGraphsTab;
