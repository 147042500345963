import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  containerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '24px',
  },
  lateralListsContainer: {
    maxWidth: '290px',
    width: '100%',
  },

  // WeatherReport and WeatherForecast
  divider: {
    margin: '5px 0px',
    width: '100%',
  },
  lastUpdatedText: {
    color: theme.palette.primary.main,
  },
  lastUpdatedDate: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  cardContainer: {
    marginBottom: '30px',
  },
  noFarmSelectedText: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.body1.fontSize,
  },

  // DailyForecast
  dailyForecastBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  weatherText: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
  },
  rainyWeatherText: {
    color: '#3693D5',
    marginBottom: 0,
  },
  rainText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'bold',
  },
  rainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#3693D5',
    marginBottom: theme.spacing(1),
  },

  // AccountHomeMap
  cardLayoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  cardLayoutContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  cardHeaderLayoutContentContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  googleMapsContainer: {
    height: '500px',
    border: '1px solid black',
    marginTop: '10px',
  },
  layoutMapChip: {
    marginRight: '10px',
  },
}));

export default useStyles;
