import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import Fullscreen from 'highcharts/modules/full-screen';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useStyles from './styles';
import ChartFilter from './ChartFilter';

// Initialize the modules
Exporting(Highcharts);
Fullscreen(Highcharts);

function BaseHighchart({
  chartType = 'line',
  title = '',
  series = [],
  categories = [],
  yAxisTitle = '',
  xAxisTitle = '',
  colors = ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
  legend = { enabled: true },
  tooltip = {},
  chartConfig = {}, // Configuraciones personalizadas adicionales
  hideFilter = false,
  sectorId = '',
  tooltipText = '',
  showFullScreen = false,
  showMarkers = false,
  plotBands = [],
  ...props
}: {
  chartType?: string,
  title?: string,
  series?: Array<Highcharts.SeriesOptionsType>,
  categories?: Array<string>,
  yAxisTitle?: string,
  xAxisTitle?: string,
  colors?: Array<string>,
  legend?: Highcharts.LegendOptions,
  tooltip?: Highcharts.TooltipOptions,
  chartConfig?: any,
  hideFilter?: boolean,
  sectorId: string,
  tooltipText?: string,
  showFullScreen?: boolean,
  showMarkers?: boolean,
  plotBands?: Array<object>,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const defaultFontStyles = {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#385576',
    fontFamily: "'Roboto', sans-serif",
    letterSpacing: '0.25px',
  };

  const chartOptions = {
    chart: {
      type: chartType,
      alignTicks: true,
      marginLeft: 80,
      height: 500,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      title: {
        text: xAxisTitle,
        style: {
          ...defaultFontStyles,
        },
      },
      alignTicks: true,
      tickmarkPlacement: 'on',
      gridLineWidth: 1,
      gridLineColor: '#E0E0E0',
      gridLineDashStyle: 'Dot',
    },
    yAxis: {
      title: {
        text: yAxisTitle,
        style: {
          ...defaultFontStyles,
          fontSize: '14px',
        },
        margin: 16,
      },
      labels: {
        style: {
          color: '#385576',
        },
      },
      plotBands: plotBands?.map((band: any) => ({
        from: band.from,
        to: band.to,
        color: band.color,

        label: {
          text: band.name || '',
          verticalAlign: 'bottom',
          align: 'right',
          y: -2,
          x: -10,
          useHTML: true, // Habilitar HTML para estilos personalizados
          style: {
            color: '#000', // Color predeterminado para etiquetas
            fontSize: '12px', // Tamaño predeterminado para las etiquetas
          },
        },
      })),
    },
    legend: {
      ...legend,
      itemStyle: {
        ...defaultFontStyles,
      },
      verticalAlign: 'top',
      layout: 'horizontal',
      align: 'left',
      itemDistance: 20,
      itemMarginBottom: 36,
      x: 10,
    },
    tooltip: {
      ...tooltip,
      style: {
        ...defaultFontStyles,
      },
    },
    colors,
    series,
    credits: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        centerInCategory: true,
      },
      line: {
        marker: {
          enabled: showMarkers,
        },
      },
      column: {
        stacking: 'normal',
      },
    },
    lang: {
      noData: 'No hay datos para mostrar',
    },
    noData: {
      style: {
        fontSize: '18px',
        fontWeight: '400px',
      },
    },
    exporting: {
      enabled: showFullScreen,
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen'],
        },
      },
    },
  };

  const options = {
    ...chartOptions,
    ...chartConfig,
    ...props,
  };

  return (
    <Card className={classes.chartCard}>
      <CardContent>
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          elevation={0}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4" color="primary">
                {title}
              </Typography>
              {tooltipText && (
                <Tooltip
                  title={
                    <div className={classes.tooltipText}>
                      {tooltipText}
                    </div>
                  }
                  arrow
                >
                  <div style={{ cursor: 'help' }}>
                    <span className={classes.tooltip}>
                      !
                    </span>
                  </div>
                </Tooltip>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {!hideFilter && (
              <ChartFilter sectorId={sectorId} />
            )}
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
}

export default BaseHighchart;
